
function WinnerModal2({ score }) {
    return (
        ( score > 30 && score <= 50 && (
            <img src="https://media2.giphy.com/media/2UvAUplPi4ESnKa3W0/200w.webp?cid=ecf05e47xxn0rg029oqxbua1h1uladhhgrtz6u2f2qtnwn5w&ep=v1_gifs_search&rid=200w.webp&ct=g" />
        ))
    )
}

export default WinnerModal2;
