

function WinnerModal({ score }) {
    return (
        ( score > 50 && score <= 70 && (
            <img src="https://media0.giphy.com/media/PVfSR2nddzGz6/giphy.webp?cid=ecf05e47wjdshvmnusct2ccxkdky2mrlw96jdj5ge27z8f52&ep=v1_gifs_search&rid=giphy.webp&ct=g" />
        ))
    )
}

export default WinnerModal;
